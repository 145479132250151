<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="1000"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >
                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Текст_исполнения") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <div class="onlyReadData">
                                    {{ executionObject.Data.Object.Comment }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="hasAttachments"
                            no-gutters
                        >
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Вложения") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">

                                <div class="cloud-file-list-box">
                                    <div class="cloud-items-container">
                                        <v-row
                                            dense
                                        >
                                            <v-file-item
                                                v-for="file in executionObject.Data.Object.OtherAttachments.data_list"
                                                :key="file.id"
                                                :name="file.Name"
                                                :size="file.Length"
                                                :is-read-only="true"
                                                col-width="4"
                                                @on-load-attachment="onDownload(file.id, file.Name)"
                                            />
                                        </v-row>
                                    </div>
                                </div>

                            </v-col>
                        </v-row>

                        <v-row
                            v-if="hasDocument"
                            no-gutters
                        >
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Документ") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <div class="onlyReadData">
                                    <v-document-link
                                        :id="executionObject.Data.Object.Documents.data_list[0].id"
                                        :type="'Chancellery|Documents.Document'"
                                        :text="attachedDocumentName"
                                        :is-direct-link="false"
                                        :execution-id="executionObject.Data.Object.id"
                                        :common="isCommon"
                                        @on-link-clicked="onLinkClicked"
                                    />
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green"
                    text
                    depressed
                    @click="apply(executionObject.Data.AdditionalObjects[0])"
                    v-if="canApply"
                >
                    {{ $t("Принять_исполнение") }}
                </v-btn>

                <v-btn
                    color="red"
                    text
                    depressed
                    @click="rework(executionObject.Data.AdditionalObjects[0], !canApply)"
                    v-if="canRework"
                >
                    {{ canApply ? $t("Отклонить_исполнение") : $t("Отозвать_исполнение") }}
                </v-btn>

                <v-btn
                    color="red"
                    text
                    depressed
                    @click="cancelExecution(executionObject.Data.AdditionalObjects[0])"
                    v-if="canCancel"
                >
                    {{ $t("Отозвать_исполнение") }}
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Закрыть") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ExecuterAnswerViewDlg",
    data () {
        return {
            title: "Просмотр_исполнения",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            links: []
        }
    },
    computed: {
        ...mapGetters('global/dialogs/executerAnswerView',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            executionObject: 'getExecutionObject',
            isCommon: 'isCommon'
        }),
        hasDocument() {
            return this.executionObject?.Data.Object.Documents?.data_list?.length > 0
        },
        attachedDocumentName() {
            let name = this.$t('Ответ_на_документ');

            if (this.executionObject.Data.Object.Documents.data_list[0].Card.RegDate)
                name += ` ${this.$t('от_время')} ${this.$moment(this.executionObject.Data.Object.Documents.data_list[0].Card.RegDate).format('DD.MM.YYYY')}`;

            return name;
        },
        hasAttachments() {
            return this.executionObject?.Data.Object.OtherAttachments?.data_list?.length > 0;
        },
        canApply() {
            return this.executionObject?.Data.AdditionalObjects?.[1] === true;
        },
        canRework() {
            return this.executionObject?.Data.AdditionalObjects?.[2] === true;
        },
        canCancel() {
            return this.executionObject?.Data.AdditionalObjects?.[3] === true;
        }
    },
    methods: {
        ...mapActions('global/dialogs/executerAnswerView', ['apply', 'rework', 'cancel', 'cancelExecution']),
        async onDownload(attachId, attachName) {
            this.$eventBus.$emit('on-download-file', { id: attachId, name: attachName });
        },
        onLinkClicked(){
            if (this.visible)
                this.cancel();
        }
    },
    beforeDestroy() {
        this.links.forEach((link) => {
            URL.revokeObjectURL(link);
        });
    }
}
</script>